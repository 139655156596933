import { useQuery } from "react-query";
import { useCommunityFeatureFlagsContext } from "@circle-react/contexts";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import { useBlockEditorContext } from "@circle-react-shared/uikit/TipTapBlockEditor";
import { useTipTapEditorContext } from "@circle-react-uikit/TipTap/index";

interface Props {
  href: string;
  command: (item: any) => void;
}

interface UnfurlEntityLink {
  type: string;
  sgid: string;
}

export const EntityPaste = ({ href, command }: Props): any => {
  const {
    record: { unfurl_entity_links_enabled: isUnfurlEntityLinksEnabled },
  } = useCommunityFeatureFlagsContext();

  const tiptapEditorContext = useTipTapEditorContext();
  const tiptapBlockEditorContext = useBlockEditorContext();
  const { addToLocalSgidToObjectMap } =
    tiptapEditorContext || tiptapBlockEditorContext;

  useQuery(
    internalApi.richTextFields.unfurlURLs({
      params: { urls: [href] },
    }),
    {
      initialData: [],
      enabled: !!isUnfurlEntityLinksEnabled,
      onSuccess: data => {
        const entityLink: UnfurlEntityLink = data?.[0] ?? {
          type: "",
          sgid: "",
        };

        if (entityLink.type) {
          addToLocalSgidToObjectMap({
            sgid: entityLink.sgid,
            object: entityLink,
          });
          command(entityLink.sgid);
        }
      },
    },
  );

  return null;
};

EntityPaste.displayName = "EntityPaste";
