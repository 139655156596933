import { useQuery } from "react-query";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import { useBlockEditorContext } from "@circle-react-shared/uikit/TipTapBlockEditor";
import { useTipTapEditorContext } from "@circle-react-uikit/TipTap/index";
import { useOpenChatRoomUuid } from "./useOpenChatRoomUuid";

interface Props {
  href: string;
  command: (item: any) => void;
}

interface UnfurlMessageLink {
  type: string;
  sgid: string;
  is_private_message?: boolean;
}

export const ChatMessagePaste = ({ href, command }: Props): any => {
  const tiptapEditorContext = useTipTapEditorContext();
  const tiptapBlockEditorContext = useBlockEditorContext();
  const { addToLocalSgidToObjectMap } =
    tiptapEditorContext || tiptapBlockEditorContext;

  const { chatRoomUuid } = useOpenChatRoomUuid();

  useQuery(
    internalApi.richTextFields.unfurlURLs({
      params: { urls: [href], open_chat_room_uuid: chatRoomUuid },
    }),
    {
      initialData: [],
      onSuccess: data => {
        const chatMessageLink: UnfurlMessageLink = data?.[0] ?? {
          type: "",
          sgid: "",
        };
        if (chatMessageLink.type) {
          addToLocalSgidToObjectMap({
            sgid: chatMessageLink.sgid,
            object: chatMessageLink,
          });
          command(chatMessageLink.sgid);
        }
      },
    },
  );

  return null;
};

ChatMessagePaste.displayName = "ChatMessagePaste";
