import type { PasteRuleMatch } from "@tiptap/core";
import type { LinkOptions } from "@tiptap/extension-link";
import Link from "@tiptap/extension-link";

interface CustomizedLinkOptions extends LinkOptions {
  openInternalLinksInCurrentTab?: boolean;
}
const isInternalURL = (url: string): boolean => {
  try {
    const currentHost = window.location.host;
    const urlHost = new URL(url).host;

    return currentHost === urlHost;
  } catch {
    return false;
  }
};

export const CustomizedLink = Link.extend<CustomizedLinkOptions>({
  inclusive: false,
  addOptions() {
    const defaultOptions = this.parent?.();

    return {
      ...defaultOptions,
      openInternalLinksInCurrentTab: false,
    };
  },

  addAttributes() {
    const defaultAttributes = this.parent?.();

    return {
      ...defaultAttributes,
      target: {
        default: this.options.HTMLAttributes.target,
        renderHTML: attributes => {
          const target =
            this.options.openInternalLinksInCurrentTab &&
            isInternalURL(attributes.href)
              ? null
              : attributes.target;

          return { target };
        },
      },
    };
  },
  addPasteRules() {
    const parentPasteRules = this.parent?.() || [];

    return parentPasteRules.map(rule => ({
      ...rule,
      find: (text: string) => {
        // Defensive check to handle different types of 'find', this is pure TS gymnastics.
        // The important part is originalLinks is `rule.find(text)`
        const originalLinks: PasteRuleMatch[] =
          typeof rule.find === "function" ? (rule.find(text) ?? []) : [];

        // Early return if no links were found in the pasted text
        if (!originalLinks.length) {
          return originalLinks;
        }

        const liquidVariables = text.match(
          /\{\{\s*(?![%}])([^{}"]|"[^"]*")+\s*\}\}/g,
        );

        // Return unfiltered original links if no liquid variables were found in pasted text
        if (!liquidVariables?.length) {
          return originalLinks;
        }

        // Filter out links that are contained within liquid variables
        return originalLinks.filter(
          (link: PasteRuleMatch) =>
            !liquidVariables.some(variableName =>
              variableName.includes(link.text),
            ),
        );
      },
    }));
  },
  // This is a customized version of the default link parser, which excludes
  // Trix mentions since they are copied as links from rendered HTML content.
  //
  // See: https://github.com/ueberdosis/tiptap/blob/193b991acc0394305ab9799dbc656e6dbc6d1e11/packages/extension-link/src/link.ts#L119-L121
  parseHTML() {
    return [
      {
        tag: 'a[href]:not([href *= "javascript:" i]):not([class *= "mention__name"])',
      },
    ];
  },
});
